import Vue from 'vue'
import {
    Message,
  MessageBox,
    Form,
    Input,
    FormItem,
    Pagination,
    Dialog,
    Button
} from 'element-ui'
const components = [Input, Form,FormItem,Button,Message,Pagination,Dialog,
    MessageBox,]

const Element = {
    install(Vue) {
        components.forEach(component => {
            Vue.component(component.name, component)
        })
    }
}

Vue.use(Element)


Vue.prototype.$message=Message
Vue.prototype.$confirm=MessageBox.confirm