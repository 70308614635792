<template>
  <div>
    <div class='banner'>
      <img src="@/assets/img/common/act2.png" alt="">
    </div>
    <div class="act-part">
      <img class="img1" src="@/assets/img/common/act3.png" alt="">
      <div class="img2">
        <a @click="pay()" href="javascript:void(0)" class="button"></a>
      </div> 
    </div>
    <div class="explain-part">
      <img class="img3" src="@/assets/img/common/act5.png" alt="">
    </div>
    <div class="connect-way" @click="chat()">
      <img src="@/assets/img/common/connect.png" alt="">
    </div>
    <!-- <div class="pay-part">
      <p class="title">618大促商用年会员</p>
      <div class="price">
        <span>￥</span>
        698
      </div>
      <div class="advantage">超1600+款字体全媒体商用</div>
      <div class="gray-box">
        <p>1.开通会员填写授权主体，秒出Aa字库全打包商用授权书</p>
        <p>2.授权期内设计发表作品可永久使用</p>
        <p>3.发票信息可在付完款后，右上角-个人信息-订单记录-发票申请填写信息。</p>
        <p>4.本次618大促为特价活动，不支持退款，请您了解清楚再付款。</p>
        <p>5.如有其它疑问请联系您的专属客服</p>
      </div>
      <div class="pay-btn" @click="pay()">立即支付</div>
      <p class="explain">本次活动最终解释权归字格网所有</p>
    </div> -->
  </div>
</template>

<script>
import { getPrice, checkPay } from "@/api/index";

import { mapState } from "vuex";

import Cookie from "js-cookie";

import loginBox from '@/components/login'

let wx = {};

export default {
  data() {
    return {
      info: "",
      orderNo: null,
      alipay:null,
    };
  },
  computed: {
    ...mapState("order", ["order"]),
  },
  methods: {

    chat() {
      var name;
      var iWidth = 80; //弹出窗口的宽度;
      var iHeight = 60; //弹出窗口的高度;
      //window.screen.height获得屏幕的高，window.screen.width获得屏幕的宽
      var iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
      var iLeft = (window.screen.width - 10 - iWidth) / 2; //获得窗口的水平位置;
      window.open(
        `http://ddt.zoosnet.net/LR/Chatpre.aspx?id=DDT54486989&lng=cn`,
        name,
        "height=" +
          iHeight +
          ",innerHeight=" +
          iHeight +
          ",width=" +
          iWidth +
          ",innerWidth=" +
          iWidth +
          ",top=" +
          iTop +
          ",left=" +
          iLeft +
          ",toolbar=no,menubar=no,scrollbars=auto,resizable=no,location=no,status=no"
      );
    },
    // 跳转支付
    pay() {
       if (Cookie.get("Authorization") !== undefined) {
        this.getPriceInfo(14,true)
       }else{
        this.$bus.$emit("loginShow", true);
       }
      
    },
    // 获得二维码
    // async getPriceInfo(type, code, state) {
    //   await getPrice({
    //     type,
    //     code,
    //     state,
    //   })
    //     .then((res) => {
    //       console.log(res);
    //       const { num } = res.results;

    //       this.info = res.results.wxdic;
    //       this.orderNo = num;
    //       this.$store.dispatch("order/saveorderX", num);
    //       this.onBridgeReady(res.results.wxdic);
    //     })
    //     .catch((err) => {
    //       location.href = "/home";
    //     });
    // },

    async getPriceInfo(type,btn) {
      await getPrice({
        type,
        way: false,
      })
        .then((res) => {
          console.log(res);
          // const { num } = res.results;
          this.alipay=res.results.aliurl
          if(btn==true){
            window.location.href=res.results.aliurl
          }
          // this.info = res.results.wxdic;
          // this.orderNo = num;
          // this.$store.dispatch("order/saveorderX", num);
          // this.onBridgeReady(res.results.wxdic);
        })
        .catch((err) => {
          // location.href = "/home";
        });
    },

    // 检查付款状态
    async checkPayStatus(order) {
      await checkPay(order).then((res) => {
        // alert(order + "发送请求");
        // alert(res.results.statusres.results.status);
        if (res.results.status == "ORDER_SUCCESS") {
          this.$store.dispatch("login/savepersonX", "re");
          this.$router.push({ path: "/success" }, () => {});
        } else {
          // alert(order + "还没付呢");
        }
      });
    },

    sendorder(num) {
      this.$store.dispatch("order/saveorderX", num);
    },

    onBridgeReady(dataInfo) {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: dataInfo.appId, //公众号名称，由商户传入
          timeStamp: dataInfo.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: dataInfo.nonceStr, //随机串
          package: dataInfo.package,
          signType: dataInfo.signType, //微信签名方式：
          paySign: dataInfo.paySign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // alert(this.order+'存的订单1');
            // alert(JSON.parse(window.localStorage.getItem("my-app")).order.order+'存的订单2');
            var orders = JSON.parse(window.localStorage.getItem("my-app")).order
              .order;
            // alert(orders + "存的订单号");
            // alert(JSON.stringify(this));
            checkPay(orders).then((res) => {
              if (res.results.status == "ORDER_SUCCESS") {
                // this.$store.dispatch("login/savepersonX", "re");
                window.location.href = "/success";
              } else {
                // alert(order + "还没付呢");
              }
            });
            this.checkPayStatus(orders);
            // alert("发请求");
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        }
      );
    },
    // if (typeof WeixinJSBridge == "undefined"){
    //    if( document.addEventListener ){
    //        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    //    }else if (document.attachEvent){
    //        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
    //        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
    //    }
    // }else{
    //    onBridgeReady();
    // }
  },
  created() {
    wx = require("weixin-js-sdk");
  },
  mounted() {
    if (this.$route.query.code != undefined) {
      this.getPriceInfo(14);
    }
    // if (window.localStorage.getItem("my-app") !== null) {
    //   var order =  JSON.parse(window.localStorage.getItem("my-app")).order;
    //   console.log(order.order)
    //   if (order !== undefined) {
    //     this.checkPayStatus(order.order);
    //   }
    // }
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 7.07rem;
  // background: #F11F36;
  background: url("@/assets/img/common/act1.png") center/cover no-repeat #f11f36;
  position: relative;
  img {
    position: absolute;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
    width: 4.15rem;
    height: 0.88rem;
  }
}

.connect-way{
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.2rem;
  position: fixed;
  right: 0.2rem;
  top:4rem;
  img{
    width: 100%;
    object-fit: cover;
  }
}

.act-part {
  background: #f11f36;
  .img1{
    width: 6.6rem;
    height: 1.3rem;
    margin: 0 auto;
    display: block;
  }
  .img2{
    width: 7.1rem;
    height: 5.54rem;
    margin: 0.24rem auto 0;
    display: block;
    background: url("@/assets/img/common/act4.png") center/cover no-repeat;
    position: relative;
    .button{
      width: 4rem;
      height: 2rem;
      position: absolute;
      bottom: 0.7rem;
      display: block;
      left: 1.5rem;
    }
  }
}

.explain-part{
  background: #f11f36;
   padding-top: 0.25rem;
   padding-bottom: 0.8rem;
  .img3{
    width: 6.7rem;
    height: 2.1rem;
    margin: 0 auto;
    display: block;
  }
}

.pay-part {
  text-align: center;
  margin-top: 0.36rem;
  .title {
    font-size: 0.3rem;
    color: #333333;
    margin-bottom: 0.23rem;
  }
  .price {
    color: #f7333d;
    font-size: 0.7rem;
    margin-bottom: 0.24rem;
    span {
      font-size: 0.4rem;
    }
  }
  .advantage {
    width: 3.2rem;
    height: 0.42rem;
    line-height: 0.42rem;
    background: #fff0e5;
    border-radius: 0.21rem;
    margin: 0 auto 0.3rem;
    color: #ff6f0b;
    font-size: 0.24rem;
    text-align: center;
  }
  .gray-box {
    width: 96%;
    background: #f5f5f5;
    border-radius: 0.1rem;
    margin: 0 auto 0.66rem;
    font-size: 0.24rem;
    padding: 0.3rem 0.3rem 0.2rem;
    box-sizing: border-box;
    text-align: left;
    p {
      margin-bottom: 0.1rem;
    }
  }
  .pay-btn {
    width: 6.2rem;
    height: 0.96rem;
    line-height: 0.96rem;
    color: #fff;
    font-size: 0.32rem;
    background: #f39928;
    border-radius: 0.1rem;
    margin: 0 auto 0.29rem;
  }
  .explain {
    font-size: 0.22rem;
    color: #999999;
  }
}
</style>