import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import router from '@/router'
import store from './store'

import '@/utils/shipei'

import Bus from '@/utils/bus'
Vue.prototype.$bus = Bus 

import '@/utils/element.js'

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
