<template>
  <div>
    <div class="write-info" v-if="hascompany==false">
      <h1 class="title">公司信息填写</h1>
      <div class="mobile-part">
        <el-form :model="compForm" :rules="compRules" ref="compForm" class="auth-Form">
          <el-form-item label="公司抬头:" prop="name">
            <el-input v-model="compForm.name" placeholder="请输入公司抬头"></el-input>
          </el-form-item>
          <el-form-item label="公司税号:" prop="num">
            <el-input v-model="compForm.num" placeholder="请输入公司税号"></el-input>
          </el-form-item>
          <el-form-item label="开户银行:" prop="bank">
            <el-input v-model="compForm.bank" placeholder="请输入开户银行"></el-input>
          </el-form-item>
          <el-form-item label="银行账号:" prop="bank_num">
            <el-input v-model="compForm.bank_num" placeholder="请输入银行账号"></el-input>
          </el-form-item>
          <el-form-item label="联系电话:" prop="phone">
            <el-input v-model="compForm.phone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="邮箱:" prop="email">
            <el-input v-model="compForm.email" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item label="公司地址:" prop="address">
            <el-input v-model="compForm.address" placeholder="请输入公司地址"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="login-btn" @click="saveCompanyApply(compForm)">确认提交</div>

          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="complete-info" v-if="hascompany==true">
      <h1>您已完成公司信息填写</h1>
      <h1>前往www.5ifont.cn获取授权</h1>
    </div>
  </div>
</template>

<script>
import { companyApply, myCompany } from "@/api/index";

export default {
  data() {
    return {
      isAchieve: false,
      hascompany:null,
      // 公司信息表单
      compForm: {
        name: "",
        num: "",
        bank: "",
        bank_num: "",
        email: "",
        phone: "",
        address: "",
      },
      // 公司信息验证规则
      compRules: {
        name: [{ required: true, message: "请输入企业抬头", trigger: "blur" }],
        num: [
          { required: true, message: "请输入纳税人识别号", trigger: "blur" },
        ],
        bank: [{ required: true, message: "请输入开户银行", trigger: "blur" }],
        bank_num: [
          { required: true, message: "请输入开户银行账号", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入接受邮箱地址", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        address: [
          { required: true, message: "请输入公司地址", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 提交公司信息
    async saveCompanyApply(form) {
      await companyApply(form)
        .then((res) => {
          location.reload();
        })
        .catch((err) => {});
    },

    // 获取公司信息来提交授权申请
    getmyCompany(name, id) {
      myCompany().then((res) => {
        if (res.results === null) return;
        if(res.results.length==0){
          this.hascompany=false
        }else if(res.results.length>0){
          this.hascompany=true
        }
        
      });
    },
  },
  created() {
    this.getmyCompany();
  },
};
</script>

<style lang="less" scoped>
.write-info {
  .title {
    font-size: 0.52rem;
    color: #333;
    text-align: center;
    margin-top: 0.3rem;
  }

  .mobile-part {
    width: 100%;
    margin-top: 0.5rem;
    /deep/ .el-form {
      width: 6.2rem;
      margin: 0 auto;
      .el-form-item {
        margin-bottom: 0.3rem;
      }
    }
    /deep/ .el-input-group__append {
      border-top-right-radius: 0.1rem;
      border-bottom-right-radius: 0.1rem;
      background: #fff;
      color: #000;
      font-size: 0.3rem;
    }

    /deep/ .el-input__inner {
      font-size: 0.3rem;
      height: 0.96rem;
      line-height: 0.96rem;
      border: 1px solid #dcdfe6;
      border-radius: 0.1rem;
      padding-left: 0.3rem;
    }
    /deep/ .input-with-select {
      .el-input__inner {
        border-radius: 0.1rem 0 0 0.1rem;
        border-right: none;
      }
    }

    .login-btn {
      width: 100%;
      height: 0.96rem;
      background: #f39928;
      border-radius: 0.1rem;
      font-size: 0.32rem;
      text-align: center;
      color: #fff;
      line-height: 0.96rem;
      margin-top: 0.5rem;
    }
  }
}

.complete-info {
  margin-top: 3rem;
  h1 {
    font-size: 0.52rem;
    color: #333;
    text-align: center;
    margin-top: 0.3rem;
  }
}
</style>