<template>
  <div>
    <div class="pay-part">
      <div class="icon">
        <img src="@/assets/img/common/success.png" alt="">
      </div>
      <p>恭喜您开通会员成功！</p>
      <p>前往绑定公司信息</p>
      <div class="pay-btn" @click="bind()">立即绑定</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    bind(){
       this.$router.push({ path: '/company' },()=>{});
    }
  },
  created() {},
  mounted(){
    this.$store.dispatch("login/savepersonX", "re");
  }
};
</script>

<style lang="less" scoped>
.pay-part {
  text-align: center;
  margin-top: 1.6rem;
  .icon {
    width: 1.12rem;
    height: 1.12rem;
    margin: 0 auto 0.52rem;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  p {
    font-size: 0.32rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 0.1rem;
  }
  .pay-btn {
    width: 6.2rem;
    height: 0.96rem;
    line-height: 0.96rem;
    color: #fff;
    font-size: 0.32rem;
    background: #f39928;
    border-radius: 0.1rem;
    margin: 0.5rem auto 0.29rem;
  }
}
</style>