import Vue from "vue";
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';

const vuexLocalStorage = new VuexPersist({
    key: 'my-app',
    storage: window.localStorage,
  });

Vue.use(Vuex)

import login from "./login"

import order from "./order"

import fontlist from './list'


export default new Vuex.Store({
    modules:{
        login,
        order,
        fontlist
    },
    plugins: [vuexLocalStorage.plugin],
})
