<template>
  <div class="back">
    <div class='tit1'>
      选择会员类型
    </div>
    <div class="pay-part">
      <div class="cprice-list">
        <div v-for="(item, index) in cPriceList" class="price-box cprice-box" :key="index"
          @click="chooseBox(index ,item.price,item.tid),getPriceInfo(item.tid),changeday(index)">
          <div class="cprice-box-content" :class="[index== nPriceIndex ? 'active' : '']">
            <div class="time-line">{{ item.title }}</div>
            <!-- 价格 -->
            <div class="price">
              <span>￥</span><span class="num">{{ item.myprice }}</span>

            </div>
            <div class="oprice">
              <s>{{ item.original_price }}</s>
              <span>{{item.tid==22?'/季':'/年'}}</span>
            </div>
            <!-- 描述 -->
            <div class="describe">
              {{ item.des }}
            </div>
            <!-- 选中 -->
            <div class="choose" v-if="index == nPriceIndex">
              <i></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 未登录 -->
    <div class="vip-auth">
      <p>商用会员权益</p>
      <div class="auth-table" v-if="loginStatus==false">
        <div class="auth-item">
          <img src="@/assets/img/common/auth1.png" alt="">
          <p>线上线下</p>
          <p>商用无忧</p>
        </div>
        <div class="auth-item">
          <img src="@/assets/img/common/auth2.png" alt="">
          <p>提供官方</p>
          <p>授权证书</p>
        </div>
        <div class="auth-item">
          <img src="@/assets/img/common/auth3.png" alt="">
          <p>全球范围</p>
          <p>商用授权</p>
        </div>
      </div>
      <!-- 续费样式 -->
      <div class="extend-box" v-if="loginStatus==true">
        <div class="data1">您的会员权益将于<span>{{authEndTime}}</span>过期</div>
        <div class="data2">续费<span class="plusday">{{daytype==0?'90':'365'}}天</span>至<span
            class="plustime">{{daytype==0?authPlusTime:authPlusTime1}}</span>
        </div>
        <div class="item item1">
          <img src="@/assets/img/common/icons1.png" alt="">
          过期后不再享受授权保护
        </div>
        <div class="item item2">
          <img src="@/assets/img/common/icons2.png" alt="">
          使用字体发布的作品需要下架
        </div>
        <div class="item item2">
          <img src="@/assets/img/common/icons2.png" alt="">
          再次发布作品会导致侵权
        </div>
      </div>
    </div>

    <div class="pays">
      <div class="pay-btn" @click="pay()">
        立即支付 ¥<span>{{nPrice}}</span>/{{nPriceIndex==0?'季':'年'}}
      </div>
      <div class="agreement">
        支付即同意<router-link :to="{ path: '/authagreement' }" target="_blank">《授权服务协议》</router-link>
      </div>
    </div>
    <div class="connect-way" @click="chat()">
      <img src="@/assets/img/common/connect.png" alt="">
    </div>
  </div>
</template>

<script>
import { getPrice, checkPay, getUnloginPrice, getPriceList } from "@/api/index";

import { mapState } from "vuex";

import Cookie from "js-cookie";

import loginBox from "@/components/login";

let wx = {};

export default {
  data() {
    return {
      info: "",
      orderNo: null,
      alipay: null,
      cPriceList: [],
      nPriceIndex: 0,
      daytype: 0,
      nPriceId: 22,
      nPrice: 398,
    };
  },
  computed: {
    ...mapState("order", ["order"]),
    ...mapState("login", ["userInfo", "loginStatus"]),
    authEndTime() {
      var time = new Date(parseInt(this.userInfo.license_expirationtime) * 1000)
        .toLocaleString()
        .replace(/:\d{1,2}$/, " ");
      return time.substr(0, time.length - 6);
    },
    authPlusTime() {
      var plus = 90 * 24 * 60 * 60 * 1000;
      var time = new Date(
        parseInt(this.userInfo.license_expirationtime) * 1000 + plus
      )
        .toLocaleString()
        .replace(/:\d{1,2}$/, " ");
      return time.substr(0, time.length - 6);
    },
    authPlusTime1() {
      var plus = 365 * 24 * 60 * 60 * 1000;
      var time = new Date(
        parseInt(this.userInfo.license_expirationtime) * 1000 + plus
      )
        .toLocaleString()
        .replace(/:\d{1,2}$/, " ");
      return time.substr(0, time.length - 6);
    },
  },
  methods: {
    chat() {
      var name;
      var iWidth = 80; //弹出窗口的宽度;
      var iHeight = 60; //弹出窗口的高度;
      //window.screen.height获得屏幕的高，window.screen.width获得屏幕的宽
      var iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
      var iLeft = (window.screen.width - 10 - iWidth) / 2; //获得窗口的水平位置;
      window.open(
        `http://ddt.zoosnet.net/LR/Chatpre.aspx?id=DDT54486989&lng=cn`,
        name,
        "height=" +
          iHeight +
          ",innerHeight=" +
          iHeight +
          ",width=" +
          iWidth +
          ",innerWidth=" +
          iWidth +
          ",top=" +
          iTop +
          ",left=" +
          iLeft +
          ",toolbar=no,menubar=no,scrollbars=auto,resizable=no,location=no,status=no"
      );
    },
    // 跳转支付
    pay() {
      if (Cookie.get("Authorization") !== undefined) {
        this.getPriceInfo(this.nPriceId, true);
      } else {
        this.$bus.$emit("loginShow", true);
      }
    },

    changeday(index) {
      this.daytype = index;
    },

    chooseBox(index, price, tid) {
      clearInterval(this.timer);
      this.nPriceIndex = index;
      this.nPrice = price;
      this.nPriceId = tid;
    },

    async getPriceListInfo() {
      if (Cookie.get("Authorization") === undefined) {
        await getUnloginPrice().then((res) => {
          console.log(res.results);
          //   this.dealPrice(false, res.results);
          this.cPriceList = res.results["时长商用"];
        });
      } else {
        await getPriceList().then((res) => {
          this.cPriceList = res.results["时长商用"];
          // console.log(res.results);
          //   this.dealPrice(true, res.results);
        });
      }
    },

    async getPriceInfo(type, btn) {
      await getPrice({
        type,
        way: false,
      })
        .then((res) => {
          // console.log(res);
          // const { num } = res.results;
          this.alipay = res.results.m_alipay;
          if (btn == true) {
            window.location.href = res.results.m_alipay;
          }
          // this.info = res.results.wxdic;
          // this.orderNo = num;
          // this.$store.dispatch("order/saveorderX", num);
          // this.onBridgeReady(res.results.wxdic);
        })
        .catch((err) => {
          console.log(err);
          //   location.href = "/home";
        });
    },

    // 检查付款状态
    async checkPayStatus(order) {
      await checkPay(order).then((res) => {
        // alert(order + "发送请求");
        // alert(res.results.statusres.results.status);
        if (res.results.status == "ORDER_SUCCESS") {
          this.$store.dispatch("login/savepersonX", "re");
          this.$router.push({ path: "/success" }, () => {});
        } else {
          // alert(order + "还没付呢");
        }
      });
    },

    sendorder(num) {
      this.$store.dispatch("order/saveorderX", num);
    },

    onBridgeReady(dataInfo) {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: dataInfo.appId, //公众号名称，由商户传入
          timeStamp: dataInfo.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: dataInfo.nonceStr, //随机串
          package: dataInfo.package,
          signType: dataInfo.signType, //微信签名方式：
          paySign: dataInfo.paySign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // alert(this.order+'存的订单1');
            // alert(JSON.parse(window.localStorage.getItem("my-app")).order.order+'存的订单2');
            var orders = JSON.parse(window.localStorage.getItem("my-app")).order
              .order;
            // alert(orders + "存的订单号");
            // alert(JSON.stringify(this));
            checkPay(orders).then((res) => {
              if (res.results.status == "ORDER_SUCCESS") {
                // this.$store.dispatch("login/savepersonX", "re");
                window.location.href = "/success";
              } else {
                // alert(order + "还没付呢");
              }
            });
            this.checkPayStatus(orders);
            // alert("发请求");
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        }
      );
    },
    // if (typeof WeixinJSBridge == "undefined"){
    //    if( document.addEventListener ){
    //        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    //    }else if (document.attachEvent){
    //        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
    //        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
    //    }
    // }else{
    //    onBridgeReady();
    // }
  },
  created() {
    wx = require("weixin-js-sdk");
  },
  mounted() {
    this.getPriceListInfo();
  },
};
</script>

<style lang="less" scoped>
.back {
  background-color: #f8f8f8;
  padding-top: 0.3rem;
  .tit1 {
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    background-color: #fff;
    padding-left: 0.8rem;
    box-sizing: border-box;
    font-size: 0.32rem;
    font-weight: 500;
    color: #040000;
    margin-bottom: 0.3rem;
  }

  .pay-part {
    width: 100%;
    padding-bottom: 0.4rem;
    background-color: #fdfbee;
    padding-top: 0.4rem;
    .cprice-list {
      display: flex;
      justify-content: center;
      .cprice-box {
        position: relative;
        &:first-child {
          margin-right: 0.13rem;
        }
        .cprice-box-content {
          width: 3.4rem;
          box-sizing: border-box;
          //   background: #ffffff;
          border: 0.04rem solid #afaeae;
          border-radius: 8px;
          text-align: center;
          overflow: hidden;
          position: relative;
          padding-top: 0.2rem;
          cursor: pointer;

          &.active {
            border: 0.04rem solid #d75265;
            box-shadow: 0px 6px 10px 0px rgba(254, 78, 77, 0.2);
          }
          .time-line {
            color: #afaeae;
            text-align: center;
            font-weight: bold;
            font-size: 0.3rem;
            margin-bottom: 0.1rem;
          }
          &.active .time-line {
            color: #d75265;
          }
          .price {
            color: #afaeae;
            font-size: 0.2rem;
            height: 0.7rem;
            font-weight: bold;
            margin: 0 auto 0.1rem;
            .num {
              font-size: 0.6rem;
              font-weight: 600;
            }
          }
          &.active .price {
            color: #d75265;
            .num {
              color: #d75265;
            }
          }
          .oprice {
            display: block;
            background-color: #d6d5ce;
            color: #afaeae;
            font-size: 0.2rem;
            width: 30%;
            margin: 0 auto 0.7rem;
          }
          &.active .oprice {
            background-color: #f3ce9e;
            color: #d75265;
          }

          .describe {
            font-size: 0.2rem;
            font-weight: 400;
            position: absolute;
            width: 100%;
            height: 0.6rem;
            line-height: 0.6rem;
            background: #afaeae;
            color: #fff;
            bottom: 0;
          }
          &.active .describe {
            background: #d75265;
            color: #ffffff;
          }
          .auth {
            font-size: 14px;
            font-weight: 400;
            color: #888;
            margin-bottom: 19px;
          }
        }
      }
    }
  }

  .vip-auth {
    width: 100%;
    background: #fff;
    margin-top: 0.4rem;
    padding-top: 0.26rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
    > p {
      padding-left: 0.5rem;
      font-size: 0.32rem;
      font-weight: 500;
      color: #040000;
    }
    .auth-table {
      width: 6.9rem;
      height: 4rem;
      background: linear-gradient(180deg, #ffffff 0%, #f8d796 60%);
      border: 1px solid #f1ce9e;
      margin: 0.25rem auto;
      border-radius: 0.2rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .auth-item {
        width: 1.95rem;
        height: 3rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: url("@/assets/img/common/itembg.png") center/cover no-repeat;
        img {
          width: 1rem;
          height: 0.9rem;
          object-fit: cover;
          margin-bottom: 0.3rem;
        }
        p {
          font-size: 0.3rem;
          color: #e48b38;
          font-weight: bold;
        }
      }
    }
    .extend-box {
      width: 6.9rem;
      height: 4rem;
      background: linear-gradient(180deg, #ffffff 0%, #f8d796 60%);
      box-shadow: 2px 3px 9px 0px #f9c8c6;
      border-radius: 0.2rem;
      margin: 0.25rem auto;
      padding-top: 8px;
      box-sizing: border-box;
      text-align: center;
      .data1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.26rem;
        color: #5f5e5e;
        font-weight: bold;
        span {
          display: inline-block;
          text-decoration: underline;
          color: #fff;
          padding: 5px 18px;
          background: #a8a8a8;
          border-radius: 17px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .data2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.26rem;
        color: #5f5e5e;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
        .plusday {
          display: inline-block;
          width: 60px;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          color: #ff504d;
        }
        .plustime {
          display: inline-block;
          text-decoration: underline;
          color: #fff;
          padding: 5px 18px;
          background: #ff504d;
          border-radius: 17px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .item {
        margin: 0 auto 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding-left: 10px;
        &.item1 {
          color: #ff504d;
          font-size: 0.26rem;
          img {
            width: 0.26rem;
            height: 0.25rem;
            margin-right: 5px;
          }
        }
        &.item2 {
          width: 90%;
          margin: 0.3rem auto 0;
          background: linear-gradient(270deg, #F5D59C, #EDC569);
          img {
            margin-right: 7px;
            width: 13px;
            height: 13px;
          }
        }
      }
    }
  }

  .pays {
    position: fixed;
    bottom: 0rem;
    width: 100%;
    background-color: #fff;
    .pay-btn {
      width: 7rem;
      height: 1rem;
      margin: 0 auto;
      background-color: #efc941;
      line-height: 1rem;
      text-align: center;
      color: #643219;
      border-radius: 0.1rem;
      margin-bottom: 0.3rem;
      span {
        font-size: 0.36rem;
        font-weight: bold;
      }
    }
    .agreement {
      justify-content: center;
      display: flex;
      align-items: center;
      margin: 0 auto 0.3rem;
      a {
        color: #eecd47;
      }
    }
  }

  .connect-way {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.2rem;
    position: fixed;
    right: 0.2rem;
    top: 6rem;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>