<template>
  <div id="app">
    <Header v-if="$route.meta.show"/>
    <router-view></router-view>
  </div>
</template>

<script>

import Header from "@/components/header";

export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<style>
</style>
