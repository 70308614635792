<template>
  <div>
    <div class='banner' @click="gopayment()">
      <img src="@/assets/img/common/banner3.png" alt="">
    </div>
    <!-- <div class='bottomad' @click="gopayment()">
      <img src="@/assets/img/common/bootpic.png" alt="">
    </div> -->
    <div class="searchBox">
      <el-input type="text" placeholder="输入文案，即刻预览" v-model="searchWord" @keyup.enter.native="searchEnter(searchWord)">
      </el-input>
      <div class="pbtn" @click="searchEnter(searchWord)">预览</div>
    </div>
    <div class="font-list">
      <ul>
        <li v-for="(item, index) in fontlist" :key="index" class="font-list-box2" @click.self='toUrl(item.id)'>
          <!-- 字体信息 -->
          <div class="font-viewer" :style="{ 'font-size': pfontSize + 'px' }">
            <router-link :to="{ path: '/payments' }" target="_blank" v-if="previewText==null"
              :style="'font-family:\'' + fontStyle[index] + '\''">
              {{ item.example }}
            </router-link>
            <router-link v-if="previewText!==null" :to="{ path: '/payments' }" target="_blank"
              :style="'font-family:'+fontsstyle[index]">
              {{ previewText }}
            </router-link>
          </div>
          <div class="font-info">
            <div class="font-info-main">
              <router-link :to="{ path: '/payments' }" target="_blank" class="font-info-name">
                {{ item.name }}
              </router-link>
            </div>
            <div class="get-auth-btn" @click="gopayment()">
              获取授权
            </div>
          </div>
          <!-- 字体预览列表 -->

        </li>
      </ul>
      <div class="block" v-if="total > pageNum">
        <el-pagination :page-size="pageNum" layout=" prev,pager, next" :total="total" prev-text="上一页" next-text="下一页"
          @current-change="pageChange" :current-page="parseInt($route.query.page)"> </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { indexFont } from "@/api/index.js";

import { mapState } from "vuex";

export default {
  data() {
    return {
      searchWord: "",
      fontlist: [],
      viewStatus: 1,
      fontStyle: [],
      fontarray: [], //拆分预览内容的去重数组
      // 字体名称
      fontName: [],
      fontFamily: [], //字体样式合集
      fontsstyle: [], //字体样式css类名
      total: null,
      pageNum: 20,
    };
  },
  methods: {
    getfontlist(page) {
      indexFont(page).then((res) => {
        // console.log(res);
        this.fontlist = res.results;
        this.total = res.count;
        this.previewList(this.fontlist, this.fontStyle, "versecss");
        for (let k = 0; k < this.fontlist.length; k++) {
          this.fontName[k] = [];
          this.fontFamily[k] = [];
          this.fontsstyle[k] = [];
        }
        // if(this.previewList!=null){
        //   this.viewFont(this.previewText)
          
        // }
      });
    },

    pageChange(val) {
      // this.query("page", val);
      const query = JSON.parse(JSON.stringify(this.$route.query));
      if (this.$route.query.page != undefined) {
        delete query["page"];
      }

      query["page"] = val;

      this.$router.push({ path: this.$route.path, query });
    },

    gopayment() {
      this.$router.push({ path: "/payments" }, () => {});
    },

    searchEnter(text) {
      this.$store.dispatch("fontlist/previewwordX", text);
    },

    // 默认预览
    previewList(list, csslist, csname) {
      for (var i = 0; i < list.length; i++) {
        let cssstyle = list[i][csname];

        if (cssstyle == "error") {
          csslist.push(cssstyle);
        } else {
          cssstyle =
            cssstyle.replace(/\n/g, "").split("{")[1].split('"')[1] +
            list[i].id;
          csslist.push(cssstyle);
          var style = document.createElement("style");
          var bodystyle = list[i][csname].replace(/";/, list[i].id + '";');
          style.type = "text/css";
          style.innerHTML = bodystyle;
          document.getElementsByTagName("head").item(0).appendChild(style);
        }
      }
    },
    // 监视数据变化初始化
    handleInit() {},

    // 设置样式
    setstyle(text, cssname) {
      var bodystyle = `@font-face {font-family: "${text}";src: url("${cssname}.eot"); /* IE9 */src: url("${cssname}.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */url("${cssname}.woff2") format("woff2"), /* chrome 36+, firefox 39+,iOS 10+, Android 67+ */url("${cssname}.woff") format("woff"), /* chrome, firefox */url("${cssname}.ttf") format("truetype"), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */url("${cssname}.svg#miaozi-haibaoti") format("svg"); /* iOS 4.1- */font-style: normal;font-weight: normal;}`;
      var style = document.createElement("style");
      style.type = "text/css";
      style.innerHTML = bodystyle;
      document.getElementsByTagName("head").item(0).appendChild(style);
    },

    // 列表预览
    viewFont(word) {
      // console.log(this.fontlist)
      for (let i in this.fontlist) {
        this.fontarray = Array.from(new Set(word.split("")));
        for (let j in this.fontarray) {
          const text = this.$md5(
            "zgwttfb4hj2b2t7ng5j2rl50cww5zueewayt" +
              this.fontlist[i].id +
              this.fontarray[j]
          );
          const cssname =
            "https://file.5ifont.cn/style/fonts/single/css/" +
            this.fontlist[i].id +
            "/" +
            text;

          this.fontName[i] = text;

          this.fontFamily[i].push(this.fontName[i]);

          const value = "'" + this.fontFamily[i].join("','") + "'";

          this.$set(this.fontsstyle, i, value);

          this.setstyle(text, cssname);

          const myFont = new FontFace(
            this.fontName[i],
            "url(" + cssname + ".woff2)"
          );

          myFont
            .load()
            .then((res) => {})
            .catch((err) => {
              console.log(err)
              const url =
                "https://font.v2.zige88.com/v2/api/font/css?id=" +
                this.fontlist[i].id +
                "&text=" +
                this.fontarray[j];
              this.$axios.get(url).then((res) => {
                  const text = this.$md5(
                    "zgwttfb4hj2b2t7ng5j2rl50cww5zueewayt" +
                      this.fontlist[i].id +
                      this.fontarray[j]
                  );
                  const cssname =
                    "https://file.5ifont.cn/style/fonts/single/css/" +
                    this.fontlist[i].id +
                    "/" +
                    text;
                  this.fontName[i] = text;
                  const text1 = text + "1";
                  this.fontFamily[i].push(`${this.fontName[i]}1`);

                  const value = "'" + this.fontFamily[i].join("','") + "'";

                  this.$set(this.fontsstyle, i, value);

                  this.setstyle(text1, cssname);
                })
                .catch((err) => {});
            });
        }
      }
    },
  },

  watch: {
    previewText() {
      this.viewFont(this.previewText);
    },
    fontlist(){
      if(this.previewText!=null){
        this.viewFont(this.previewText);
      }
      
    },
    //监听query变化
    "$route.query": function (to, from) {
      
      location.reload();
    },
  },

  computed: {
    ...mapState("fontlist", ["pfontSize", "previewText"]),
  },

  created() {
    this.getfontlist(this.$route.query.page||1);
  },
  mounted() {
    this.getfontlist(this.$route.query.page||1);
    // console.log(this.fontlist.length)
    // for (let k = 0; k < 20; k++) {
    //   this.fontName[k] = [];
    //   this.fontFamily[k] = [];
    //   this.fontsstyle[k] = [];
    // }
    
    // this.$nextTick(()=>{
    //   if(this.previewText!=null){
    //   console.log(this.previewText)
    //   this.viewFont(this.previewText);
    // }
    // })
    
    // console.log(this.fontlist);
    // this.previewList(this.fontlist, this.fontStyle, "versecss");
    
  },
};
</script>

<style lang="less" scoped>
//分页部分
.el-pagination {
  white-space: nowrap;
  padding: 2px 5px;
  color: #666;
  font-weight: normal;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96%;
  margin: 0.3rem auto 0;
  margin-bottom: 1.5rem;
}

/deep/ .el-pagination span:not([class*="suffix"]) {
  font-size: 0.16rem;
  height: 0.5rem;
  line-height: 0.5rem;
}

/deep/ .el-pagination button {
  font-size: 0.16rem;
  width: 0.8rem;
  height: 0.5rem;
  line-height: 0.5rem;
  text-align: center;
}

/deep/ .el-pagination .btn-next {
  padding-left: 0px;
}

/deep/ .el-pagination .btn-prev {
  padding-right: 0px;
}

/deep/ .el-pagination button:hover {
  color: #ff6f0b;
}

/deep/ .el-pager {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

/deep/ .el-pager li {
  font-size: 0.16rem;
  width: 0.5rem;
  min-width: 0.5rem;
  height: 0.5rem;
  line-height: 0.5rem;
  background-color: #f4f4f4;
  border-radius: 4px;
  margin-right: 0.1rem;
}

/deep/ .el-pager li.active {
  color: #fff;
  background-color: #ff6f0b;
  cursor: default;
}

/deep/ .el-pager li:hover {
  color: #ff6f0b;
}

.banner {
  width: 100%;
  height: 2.69rem;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.bottomad {
  width: 100%;
  height: 1.2rem;
  position: fixed;
  bottom: 0;
  z-index: 200;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.searchBox {
  width: 96%;
  height: 0.72rem;
  box-sizing: border-box;
  display:flex;
  margin: 0.2rem auto 0;
  align-items: center;
  justify-content: space-between;
  .pbtn{
    width: 20%;
    height: 0.72rem;
    line-height: 0.72rem;
    background: #000;
    border-radius: 4px;
    color: #f4f4f4;
    text-align: center;
  }
  /deep/ .el-input {
    width: 70%;
    display: block;
    margin: 0 auto;
  }
  /deep/ .el-input__prefix {
    display: flex;
    align-items: center;
    padding-left: 11px;
  }

  /deep/ .el-input__inner {
    height: 0.72rem;
    line-height: 0.72rem;
    background: #fff;
    border-radius: 4px;
    font-size: 0.16rem;
    font-weight: 400;
    color: #7d7a7a;
    padding-left: 0.17rem;
    border: 1px solid #e6e6e6;
  }
}

.font-list {
  ul {
    height: 7.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    .font-list-box2 {
      width: 96%;
      box-shadow: 0 0.04rem 0.1rem 0 rgba(0, 0, 0, 0.1);
      margin: 0 auto 0.1rem;
      padding: 0.08rem;
      box-sizing: border-box;
      cursor: pointer;
      transition: 0.3s;
      background-color: #fff;
      position: relative;
      border-bottom: 1px solid #f4f4f4;
      border-radius: 0.15rem;
      .font-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.08rem;
        .font-info-main {
          display: flex;
          align-items: center;
          .font-info-name {
            display: inline-block;
            font-size: 0.14rem;
            color: #000;
            margin-left: 0.1rem;
          }
        }
        .get-auth-btn {
          width: 1.6rem;
          height: 0.42rem;
          line-height: 0.42rem;
          background: #fff8f2;
          border: 1px solid #ff6f0b;
          border-radius: 0.2rem;
          font-size: 0.16rem;
          font-weight: 400;
          color: #ff6f0b;
          text-align: center;
          cursor: pointer;
          box-sizing: border-box;
        }
      }
      .font-viewer {
        font-weight: 400;
        color: #000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.12rem;
        a {
          display: block;
          color: #000;
          min-height: 0.4rem;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>