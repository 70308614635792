import request from "./request";

// 手机登录
export function getYzm(data) {
    return request({
        url: '/user/code',
        method: 'post',
        data
    })
}
export function mobileLogin(data) {
    return request({
        url: '/user/login',
        method: 'post',
        data
    })
}


// 获得用户信息
export function getUserInfo() {
    return request({
      url: '/user/info',
      method: 'get'
    })
  }