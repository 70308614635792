import axios from "axios";

import Cookie from 'js-cookie'

const requests = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_D,
    timeout: 5000,
    withCredentials: true
})

requests.interceptors.request.use(config => {
    config.headers['Authorization'] = 'JWT' + ' ' + Cookie.get('Authorization') || ''
    return config
})


requests.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code == 200) {
            return res

        } else if (res.code === 600) {
        } else {
            return Promise.reject(res).catch()
        }
    },
    error => {
    }
)

export default requests