<template>
  <div>
    <el-dialog class="loginBox clearfix" :visible.sync="dialogVisible" @close="handleClose"
      :close-on-click-modal='false' :class="$route.path=='/staract'?'star':''">
      <div class="title">
        <img src="@/assets/img/common/loginpic.png" alt="">
      </div>
      <div class="mobile-part">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-form-item prop="pass">
            <el-input type="text" v-model="ruleForm.pass" autocomplete="off" placeholder="请输入手机号">
              <img src="@/assets/img/common/icon1.png" class="iconMobile" slot="prefix" />
            </el-input>
          </el-form-item>
          <el-form-item prop="checkPass">
            <el-input type="text" placeholder="输入验证码" v-model="ruleForm.checkPass" class="input-with-select">
                <img src="@/assets/img/common/icon2.png" class="iconMobile" slot="prefix" />
              <el-button slot="append" @click="getTestCode(ruleForm.pass)" :disabled='isAchieve'>{{getCodeBtn}}
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="login-btn" @click="submitMobile(ruleForm.pass,ruleForm.checkPass)">登录/注册</div>

          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getYzm, mobileLogin } from "@/api/login";

import Cookie from "js-cookie";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else {
        if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
          callback("手机号格式不正确");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      // 定时器
      dialogVisible: false,
      timer: "",
      mobileTimer: "",
      isAchieve: false,
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      getCodeBtn: "获取验证码",
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  mounted(){
    this.$bus.$on("loginShow", (e) => {
      this.dialogVisible = e;
    });
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.ruleForm.code = "";
    },

    // 获取验证码
    async getTestCode(phone) {
      await getYzm({ phone: phone })
        .then((res) => {
          var seconds = 60;
          this.isAchieve = true;
          this.mobileTimer = setInterval(() => {
            seconds--;
            this.getCodeBtn = seconds + "s后重新获取";

            if (seconds == 0) {
              clearInterval(this.mobileTimer);
              this.getCodeBtn = "获取验证码";
              this.isAchieve = false;
            }
          }, 1000);
        })
        .catch((err) => {
          if (err.code === 40000) {
            this.$confirm(err.msg, err.msg, {
              type: "warning",
              confirmButtonText: "重新输入",
              cancelButtonText: "取消",
            })
              .then(() => {})
              .catch(() => {});
          }
        });
    },

    // 提交手机登录
    async submitMobile(phone, captcha) {
      await mobileLogin({
        phone: phone,
        code: captcha,
      })
        .then((res) => {
          // console.log(res)
          Cookie.set("Authorization", res.results.token, { expires: 7 });
          clearInterval(this.mobileTimer);
          this.$store.dispatch("login/savepersonX", "mobile");
        })
        .catch((err) => {
          // console.log(err);

          this.$confirm(err.message, "错误信息", {
            customClass: "energyModel",
            type: "warning",
            confirmButtonText: "重新输入",
            cancelButtonText: "取消",
          })
            .then(() => {})
            .catch(() => {});
        });
    },
  },
};
</script>

<style lang="less" scoped>
.loginBox /deep/ .el-dialog {
  width: 6.5rem;
  height: 5.64rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  position: relative;
  margin-top: 20vh !important;
}

.loginBox /deep/ .el-dialog__body {
  padding: 0px;
}

.loginBox /deep/ .el-dialog__header {
  padding: 0px;
}

.loginBox /deep/ .el-dialog__headerbtn {
  position: absolute;
  top: -0.3rem;
  right: -0.2rem;
  width: 0.4rem;
  height: 0.4rem;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  /deep/ &:hover {
    color: transparent;
  }
}

.loginBox /deep/ .el-button.is-disabled,.loginBox /deep/  .el-button.is-disabled:focus,.loginBox /deep/  .el-button.is-disabled:hover{
  border: none;
}

.loginBox /deep/ .el-dialog__wrapper {
  top: 2rem;
}

.loginBox /deep/ .el-message-box__wrapper {
  /deep/ .el-message-box {
    display: inline-block;
    width: 310px !important;
    margin-top: 3rem !important;
  }
}

.loginBox /deep/ .el-dialog__close {
  color: transparent;
  background: url("@/assets/img/common/authclose.png") center/cover no-repeat;
  width: 0.4rem;
  height: 0.4rem;
}

.title {
  width: 6.5rem;
  height: 1.45rem;
  border-radius:0.5rem 0.5rem 0 0 ;
  img {
    width: 100%;
    object-fit: cover;
     border-radius:0.5rem 0.5rem 0 0 ;
  }
}

.logo {
  margin-top: 1.5rem;
  width: 2.9rem;
  margin: 1.5rem auto 0;
  img {
    width: 100%;
    display: block;
    object-fit: cover;
  }
}

.mobile-part {
  width: 100%;
  margin-top: 0.8rem;
  .iconMobile {
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    top:0.15rem;
    left: 0.1rem;
  }
  /deep/ .el-form {
    width: 5.2rem;
    margin: 0 auto;
    .el-form-item {
      margin-bottom: 0.3rem;
    }
  }
  /deep/ .el-input-group__append {
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    background: #fff;
    color: #000;
    font-size: 0.3rem;
    border-color: #FD9164;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
  }

  

  /deep/ .el-input__inner {
    font-size: 0.3rem;
    height: 0.8rem;
    line-height: 0.8rem;
    border: 2px solid #FD9164;
    border-radius: 0.4rem;
    position: relative;
    padding-left: 0.8rem;
  }
  /deep/ .input-with-select {
    .el-input__inner {
      border-radius: 0.4rem 0 0 0.4rem;
      border-right: none;
    }
  }

  .login-btn {
    width: 4.5rem;
    height: 0.8rem;
    background: #FD9164;
    border-radius: 0.1rem;
    font-size: 0.26rem;
    text-align: center;
    color: #fff;
    line-height: 0.8rem;
    margin: 0.7rem auto 0;
    border-radius: 0.4rem;
  }
}
</style>