import Vue  from "vue";

const state = {
    // 字体列表
    pfontSize: 32,
    previewText: null,
   
}


const actions = {
    
    // 预览字体
    previewwordX(context, value) {
        context.commit("PREVIEWWORDX", value)
    },
}

const mutations = {
    
    PREVIEWWORDX(state, value) {
        if (value == '') {
            state.previewText = null
        } else {
            state.previewText = value
        }

    },
}



const getters = {}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
}