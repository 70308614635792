import request from "./request";


// 获取字体列表
export function indexFont(data) {
  return request({
    url: '/font/list?b=Aa字库&m=2&page='+data,
    method: 'get',
  })
}

// 获取公司
export function myCompany() {
  return request({
    url: '/user/company',
    method: 'get'
  })
}

// 提交公司信息
export function companyApply(data) {
  return request({
    url: '/user/company',
    method: 'post',
    data
  })
}

export function getUnloginPrice() {

  return request({
    url: '/order/price',
    method: 'get'
  })
}

// 价格列表
export function getPriceList() {
  return request({
    url: '/order/info',
    method: 'get'
  })
}

// 获取价格
export function getPrice(data) {

  return request({
    url: '/order/order',
    method: 'post',
    data
  })
}

// 检查支付状态
export function checkPay(data) {
  return request({
    url: '/order/check?num=' + data,
    method: 'get',
  })
}