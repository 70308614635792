import Cookie from "js-cookie";

import {getUserInfo} from "@/api/login"

const state=()=>({
    userInfo:{},
    loginStatus:false
})

const actions={
    // 获取用户信息
    savepersonX(context,value){
        getUserInfo().then(res=>{
            if(res.results===null) return
            context.commit('SAVEPERSONX',res.results[0])
            context.commit('LOGINX',true)
            if(value=='mobile'){
                // console.log('手机用户')
                location.reload()
            }
            if(value=='re'){
                
            }
        })
    },
    clearLogin(context){
        context.commit('CLEARLOGIN')
    }
}

const mutations={
    SAVEPERSONX(state,value){
        state.userInfo=value
        
    },
    LOGINX(state,value){
        state.loginStatus=value
    },
    CLEARLOGIN(state){
        state.userInfo={}
        state.loginStatus=false 
    }
}


export default{
    state,
    actions,
    mutations,
    namespaced:true,
}
