

const state=()=>({
    order:null
})

const actions={
    // 获取用户信息
    saveorderX(context,value){
        context.commit('SAVEORDERX',value)
    },
}

const mutations={
    SAVEORDERX(state,value){
        state.order=value
        
    },
}


export default{
    state,
    actions,
    mutations,
    namespaced:true,
}
