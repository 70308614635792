<template>
  <div>
    <loginBox />
    <div class="head">

      <div class="info">
        <div class="icon-header">
          <img src="@/assets/img/common/header.png" alt="">
        </div>
        <p v-if="loginStatus==true">ID:<span>{{userInfo.id}}</span></p>
        <input type="text" v-model="userInfo.id" id='txt'>
        <div class="bind-btn" @click="loginOut()" style="color:#FF7F1F" v-if="loginStatus==true">退出登录</div>
      </div>
      <div class="btn-group">
        <div class="bind-btn" @click="bind()" v-if="hascompany==false&&typeuser==14">绑定公司</div>
        <div class="copy-btn" @click="copy()" v-if="loginStatus==true">复制ID</div>
        <div class="copy-btn" @click="login()" v-if="loginStatus==false">登录</div>
      </div>

    </div>
  </div>

</template>

<script>
import { myCompany } from "@/api/index";

import { mapState } from "vuex";

import Cookie from "js-cookie";

import loginBox from "@/components/login";

export default {
  data() {
    return {
      hascompany: null,
      typeuser: null,
    };
  },
  components: { loginBox },
  methods: {
    


    copy() {
      var element = document.getElementById("txt");
      element.select();
      element.setSelectionRange(0, element.value.length);
      document.execCommand("copy");
      alert("已复制到剪切板");
    },
    bind() {
      this.$router.push({ path: "/company" }, () => {});
    },
    // 获取公司信息来提交授权申请
    getmyCompany(name, id) {
      myCompany().then((res) => {
        // console.log(res)
        if (res.results === null) return;
        if (res.results.length == 0) {
          this.hascompany = false;
        } else if (res.results.length > 0) {
          this.hascompany = true;
        }
      });
    },
    login() {
      this.$bus.$emit("loginShow", true);
    },
    // 退出登录
    loginOut() {
      Cookie.remove("Authorization");
      this.$store.dispatch("login/clearLogin");
      this.$router.push({ path: "/home" }, () => {});
    },
  },
  computed: {
    ...mapState("login", ["userInfo", "loginStatus"]),
  },
  mounted() {
    if (Cookie.get("Authorization") !== undefined) {
      this.getmyCompany();
      if (window.localStorage.getItem("my-app") !== null) {
        var cusetype = JSON.parse(window.localStorage.getItem("my-app")).login
          .userInfo.license_type;
        this.typeuser = cusetype;
      }
      setTimeout(() => {
        if (window.localStorage.getItem("my-app") == null) {
          this.$store.dispatch("login/savepersonX", "re");
          if (Cookie.get("Authorization") == undefined) {
            this.loginOut();
          }
        }
      }, 500);
    } else {
      this.loginOut()
      this.hascompany = false;
    }
  },
  created() {},
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 1.04rem;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px 0px #e6e6e6;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.32rem;
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon-header {
      width: 0.69rem;
      height: 0.69rem;
      border-radius: 50%;
      margin-right: 0.22rem;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    input {
      width: 0.1rem;
      opacity: 0;
    }
  }
  .btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bind-btn {
      width: 1.4rem;
      height: 0.58rem;
      line-height: 0.58rem;
      background: #000;
      text-align: center;
      border-radius: 29px;
      color: #fff;
      font-size: 0.24rem;
      margin-right: 0.1rem;
    }
    .copy-btn {
      width: 1.4rem;
      height: 0.58rem;
      line-height: 0.58rem;
      background: #fff0e5;
      text-align: center;
      border-radius: 29px;
      color: #ff6f0b;
      font-size: 0.24rem;
    }
  }
}
</style>