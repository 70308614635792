import Vue  from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter)

import Login from "@/pages/login"

import Home from '@/pages/home'

import Company from '@/pages/company'

import Pay from '@/pages/pay'

import Payments from '@/pages/payments'

import Success from '@/pages/success'

import Authagreement from '@/pages/authagreement'

import Cookie from "js-cookie";

const router= new VueRouter({
    mode:'history',
    routes:[
        {path:'/login',component:Login,meta:{show:false}},
        {path:'/home',component:Home,meta:{show:true}},
        {path:'/authagreement',component:Authagreement,meta:{show:true}},
        {path:'/company',component:Company,meta:{show:true}},
        {path:'/pay',component:Pay,meta:{show:true}},
        {path:'/payments',component:Payments,meta:{show:true}},
        {path:'/success',component:Success,meta:{show:true}},
        {path:'*',redirect:"/home"}
    ]
})
  //  判断是否需要守卫
// router.beforeEach((to, from,next) => {
  
//     if (to.path!=='/login'){
//       if(Cookie.get("Authorization")==undefined){
        
//         next()
//       }else{
//         next()
//       }
//     next()
//     }else {
//       next()
//     }
//   })

  export default router