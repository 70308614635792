<template>
  <div>
    <!-- logo -->
    <div class="logo">
      <img src="@/assets/img/common/logo.png" alt="">
    </div>
    <div class="mobile-part">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="pass">
          <el-input type="text" v-model="ruleForm.pass" autocomplete="off" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input type="text" placeholder="请输入验证码" v-model="ruleForm.checkPass" class="input-with-select">
            <el-button slot="append" @click="getTestCode(ruleForm.pass)" :disabled='isAchieve'>{{getCodeBtn}}
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="login-btn" @click="submitMobile(ruleForm.pass,ruleForm.checkPass)">登录/注册</div>

        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getYzm, mobileLogin } from "@/api/login";

import Cookie from "js-cookie";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else {
        if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
          callback("手机号格式不正确");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      // 定时器
      timer: "",
      mobileTimer: "",
      isAchieve: false,
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      getCodeBtn: "获取验证码",
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    // 获取验证码
    async getTestCode(phone) {
      await getYzm({ phone: phone })
        .then((res) => {
          var seconds = 60;
          this.isAchieve = true;
          this.mobileTimer = setInterval(() => {
            seconds--;
            this.getCodeBtn = seconds + "s后重新获取";

            if (seconds == 0) {
              clearInterval(this.mobileTimer);
              this.getCodeBtn = "获取验证码";
              this.isAchieve = false;
            }
          }, 1000);
        })
        .catch((err) => {
          if (err.code === 40000) {
            this.$confirm(err.msg, err.msg, {
              type: "warning",
              confirmButtonText: "重新输入",
              cancelButtonText: "取消",
            })
              .then(() => {})
              .catch(() => {});
          }
        });
    },

    // 提交手机登录
    async submitMobile(phone, captcha) {
      await mobileLogin({
        phone: phone,
        code: captcha,
      })
        .then((res) => {
          // console.log(res)
          Cookie.set("Authorization", res.results.token, { expires: 7 });
          clearInterval(this.mobileTimer);
          this.$store.dispatch("login/savepersonX", "mobile");
        })
        .catch((err) => {
          // console.log(err);

          this.$confirm(err.message, "错误信息", {
            customClass :'energyModel',
            type: "warning",
            confirmButtonText: "重新输入",
            cancelButtonText: "取消",
          })
            .then(() => {})
            .catch(() => {});
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-message-box__wrapper{
  /deep/ .el-message-box {
    display: inline-block;
    width:310px !important;
    margin-top: 3rem !important;
}
}


.logo {
  margin-top: 1.5rem;
  width: 2.9rem;
  margin: 1.5rem auto 0;
  img {
    width: 100%;
    display: block;
    object-fit: cover;
  }
}

.mobile-part {
  width: 100%;
  margin-top: 1.3rem;
  /deep/ .el-form {
    width: 6.2rem;
    margin: 0 auto;
    .el-form-item {
      margin-bottom: 0.3rem;
    }
  }
  /deep/ .el-input-group__append {
    border-top-right-radius: 0.1rem;
    border-bottom-right-radius: 0.1rem;
    background: #fff;
    color: #000;
    font-size: 0.3rem;
  }

  /deep/ .el-input__inner {
    font-size: 0.3rem;
    height: 0.96rem;
    line-height: 0.96rem;
    border: 1px solid #dcdfe6;
    border-radius: 0.1rem;
    padding-left: 0.3rem;
  }
  /deep/ .input-with-select {
    .el-input__inner {
      border-radius: 0.1rem 0 0 0.1rem;
      border-right: none;
    }
  }

  .login-btn {
    width: 100%;
    height: 0.96rem;
    background: #f39928;
    border-radius: 0.1rem;
    font-size: 0.32rem;
    text-align: center;
    color: #fff;
    line-height: 0.96rem;
    margin-top: 0.5rem;
  }
}
</style>